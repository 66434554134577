<template>
    <div v-if="isTypeDispatcher">
        <re-select
            ref="valuerSelect"
            :model-value="props.row.valuer?.username ?? 'n/a'"
            :disable="
                !valuers.data ||
                request.isBusy ||
                props.row.valuation_type == 'ovm' ||
                row.payment_summary?.is_payment_completed === false
            "
            :options="[
                { label: $t('monolith.dashboard.unassigned'), value: 'n/a' },
                ...valuers.data?.map((val) => ({ value: val.username, label: val.email })),
            ]"
            :bg-color="valuerSelectColor ? `${valuerSelectColor}-light` : undefined"
            :loading="valuers.isBusy || request.isBusy"
            v-bind="$attrs"
            size="md"
            @update:model-value="updateValuer($event, false)"
        />
    </div>
    <q-badge v-else-if="props.row.valuer?.username" :color="valuerSelectColor">
        {{ props.row.valuer?.username }}
    </q-badge>
    <re-dialog
        :model-value="!!newValuerConfirm"
        has-cancel
        @update:model-value="newValuerConfirm = null"
        @confirm="updateValuer(newValuerConfirm, true)"
        @cancel="$refs.valuerSelect?.cancel()"
    >
        {{ $t('monolith.dashboard.valuer_change_modal.content') }}
    </re-dialog>
</template>
<script setup>
import { inject, computed, ref } from 'vue'
import useUserList from '@/composables/userList.js'
import useRequest from '@/composables/request.js'

// don't automatically insert attrs in root div:
defineOptions({ inheritAttrs: false })

const props = defineProps({
    row: {
        type: Object,
        required: true,
    },
    // value: {
    //     type: String,
    //     required: true,
    // },
})

const curUser = inject('curUser')
const request = useRequest({
    requestRefInit: props.row.valuation_request_ref,
    startDisabled: true,
})
const valuers = useUserList({ roles: 'valuer', modules: props.row.valuation_type })
const newValuerConfirm = ref(null)

const isTypeDispatcher = computed(
    // TODO: should we also support onsite expert update from the Dashboard?
    () => curUser.hasRole('dispatcher', props.row.valuation_type) && props.row.valuation_type === 'dvm'
)

const updateValuer = (newValuer, force) => {
    if (props.row.valuer?.username && !force) {
        newValuerConfirm.value = newValuer
        return
    }
    if (newValuer !== 'n/a') request.updateValuer({ username: newValuer, steal: true })
    else request.updateValuer({ release: 'true' })
    // return axios
    //     .put(utils.val_urls(this.$config).request_ref_valuer(row.valuation_request_ref), null, {
    //         params: { username, steal: true },
    //     })
    //     .then((_response) => {
    //         // this.$set(this.data_overrides, row.valuation_request_ref, {
    //         //     valuer: response.data,
    //         // })
    //         this.closeValuerChangeModal()
    //     })
}

// TODO: make q-select display colour even when not selected
const valuerSelectColor = computed(() => {
    if (!curUser.hasRole('valuer') || props.row.status !== 'submitted') return undefined
    if (!props.row.valuer) return 'positive'
    if (props.row.valuer?.is_self) return 'primary'
    return 'negative'
})
</script>
