<template>
    <template v-if="props.isHorizontal">
        <template v-if="props.alwaysShowHorizontal || !props.flow.currentStep.hidden">
            <template v-if="$q.screen.gt.sm && props.flow.totalVisibleSteps < BREAK_POINT">
                <!-- TODO: rewrite with own CSS -->
                <q-stepper
                    :model-value="props.flow.currentStep?.rootName"
                    flat
                    header-class="text-bold"
                    active-icon="mdi-pencil-outline"
                    done-color="primary"
                    inactive-color="secondary"
                >
                    <q-step
                        v-for="(step, i) in props.flow.visibleSteps"
                        :key="`step_${i}`"
                        :name="step.name"
                        :prefix="step.visibleStepIdx + 1"
                        :title="step.label"
                        :done="step.done"
                        :icon="step.icon"
                        class="hidden"
                    />
                </q-stepper>
                <q-separator />
            </template>
            <div v-else>
                <div class="row items-center q-pa-md sticky-top">
                    <template v-if="props.flow.totalVisibleSteps >= BREAK_POINT">
                        <q-avatar
                            v-if="props.flow.currentStep.icon"
                            color="primary-light"
                            class="text-primary q-mr-sm"
                            size="md"
                            :icon="props.flow.currentStep.icon"
                        />
                        <p class="q-my-auto text-body2">
                            <strong>{{ props.flow.currentStep.label }}</strong>
                        </p>
                        <q-badge
                            v-if="props.flow.currentStep.visibleStepIdx != undefined"
                            class="q-ml-auto re-mr text-body1"
                            color="info-light"
                        >
                            <strong>
                                {{ props.flow.currentStep.visibleStepIdx + 1 }} /
                                {{ props.flow.totalVisibleSteps }}
                            </strong>
                        </q-badge>
                    </template>
                    <template v-for="(step, i) in props.flow.visibleSteps" v-else :key="i">
                        <re-icon
                            v-if="step.done"
                            name="mdi-check"
                            class="q-pa-sm bg-primary-bground rounded-borders q-mr-sm"
                            color="primary"
                        />
                        <div v-else-if="step.active" class="row items-center col-grow">
                            <div
                                class="step text-primary bg-primary-bground rounded-borders row items-center justify-center"
                            >
                                <strong>
                                    {{ i + 1 }}
                                </strong>
                            </div>
                            <strong class="text-primary q-ml-sm">
                                {{ props.flow.currentStep.label }}
                            </strong>
                        </div>
                        <div
                            v-else
                            class="step text-secondary bg-secondary-bground rounded-borders row items-center justify-center q-ml-sm"
                        >
                            <strong>{{ i + 1 }}</strong>
                        </div>
                    </template>
                </div>
                <q-separator />
            </div>
        </template>
    </template>
    <q-list
        v-else
        v-bind="$attrs"
        class="stepper"
        :class="{ 'stepper--horizontal': props.isHorizontal, 'stepper--with-tick': props.markStepsDone }"
    >
        <slot name="steps-prepend" />
        <q-item
            v-for="(step, i) in props.flow.visibleSteps"
            :key="`step_${i}`"
            :active="step.active"
            :class="{ 'done-step': step.done }"
        >
            <q-item-section avatar>
                <q-avatar size="xl" :icon="step.done && props.markStepsDone ? 'mdi-check' : step.icon">
                    <template
                        v-if="
                            (props.markStepsDone && !step.done && !step.icon) ||
                            (!props.markStepsDone && !step.icon)
                        "
                    >
                        {{ step.visibleStepIdx + 1 }}
                    </template>
                </q-avatar>
            </q-item-section>
            <q-item-section>
                <q-item-label
                    class="header"
                    :class="{ 'header--parent': step.children && step.children.length }"
                >
                    {{ step.label }}
                </q-item-label>
                <q-item-label v-if="step.caption" caption>
                    {{ step.caption }}
                </q-item-label>
                <template v-if="step.active && step.children && step.children.length">
                    <q-list class="stepper__children">
                        <template v-for="(childStep, j) in step.children">
                            <q-item
                                v-if="!childStep.hidden"
                                :key="`children_step_${j}`"
                                :active="childStep.active"
                                :class="{ 'done-step': childStep.done }"
                            >
                                <q-item-section>
                                    <q-item-label class="header">
                                        {{ childStep.label }}
                                    </q-item-label>
                                    <q-item-label v-if="childStep.caption" caption>
                                        {{ childStep.caption }}
                                    </q-item-label>
                                </q-item-section>
                            </q-item>
                        </template>
                    </q-list>
                </template>
            </q-item-section>
        </q-item>
        <slot name="steps-append" />
    </q-list>
</template>
<script setup>
const props = defineProps({
    flow: {
        type: Object,
        required: true,
    },
    isHorizontal: {
        type: Boolean,
        default: false,
    },
    alwaysShowHorizontal: {
        type: Boolean,
        default: false,
    },
    markStepsDone: {
        type: Boolean,
        default: true,
    },
})

const BREAK_POINT = 5
</script>
<style lang="scss" scoped>
.stepper {
    margin-top: 80px;

    .q-item {
        .q-avatar {
            background-color: var(--secondary-bground);
            color: var(--q-secondary);
        }

        &.done-step {
            .q-avatar {
                background-color: var(--positive-bground);
                color: $positive;
            }
        }

        &.q-router-link--active {
            .q-item__label.header {
                font-weight: bold;
            }

            .q-avatar {
                background-color: var(--primary-bground);
                color: var(--q-primary);
            }
        }
    }

    &:not(.stepper--with-tick) {
        .q-item {
            .q-avatar {
                position: relative;
                z-index: 15;
                background-color: #e4e8ed; // TODO: variable?;
                color: var(--q-secondary);
            }

            &.done-step {
                .q-avatar {
                    background-color: var(--q-primary);
                    color: $base;
                }
            }

            &.q-router-link--active {
                .q-item__label.header {
                    font-weight: bold;
                }

                .q-avatar {
                    background-color: var(--q-primary);
                    color: $base;
                }
            }
        }

        .q-item {
            &__section {
                justify-content: start;

                .q-item__label.header {
                    height: 46px;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                }

                &--avatar {
                    position: relative;
                    display: flex;

                    &:before {
                        content: '';
                        position: absolute;
                        justify-content: center;
                        left: 22px;
                        width: 2px;
                        height: calc(100% + 16px);
                        background: #e4e8ed; // TODO: variable?
                    }
                }

                &--main {
                    .q-item__label.header--parent {
                        color: var(--q-dark);
                    }
                }
            }

            &.done-step {
                .q-item__section {
                    &--avatar {
                        &:before {
                            content: '';
                            position: absolute;
                            justify-content: center;
                            left: 22px;
                            width: 2px;
                            height: calc(100% + 16px);
                            background: var(--q-primary);
                        }
                    }
                }
            }

            &:last-child {
                .q-item__section {
                    &--avatar {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }

        .stepper__children {
            .q-item {
                padding: 0;
                position: relative;

                &:not(.q-item--active) {
                    .q-item__label {
                        color: var(--q-dark);
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    justify-content: center;
                    left: -46px;
                    top: 15px;
                    width: 14px;
                    height: 14px;
                    border: 2px solid #e4e8ed; // TODO: variable?
                    background: $base;
                    border-radius: 50%;
                    z-index: 10;
                }

                &:after {
                    content: '';
                    position: absolute;
                    justify-content: center;
                    left: -41px;
                    top: 20px;
                    width: 4px;
                    height: 4px;
                    border: 2px solid #e4e8ed; // TODO: variable?
                    background: $base;
                    border-radius: 50%;
                    z-index: 10;
                }

                .q-item__section {
                    &:before {
                        content: '';
                        position: absolute;
                        justify-content: center;
                        left: -40px;
                        width: 2px;
                        height: 100%;
                        top: -23px;
                        background: #e4e8ed; // TODO: variable?
                        z-index: 5;
                    }
                }

                &.q-item--active,
                &.done-step {
                    &:before {
                        border-color: var(--q-primary);
                    }

                    &:after {
                        border-color: var(--q-primary);
                    }

                    .q-item__section {
                        &:before {
                            background: var(--q-primary);
                        }
                    }
                }
            }
        }
    }
}

.step {
    width: 30px;
    height: 30px;
}

:deep(.q-stepper__header--standard-labels) {
    .q-stepper__tab {
        min-height: 56px; // force it to be same height as the stepper
    }
}

:deep(.q-stepper__tab .q-stepper__dot) {
    border-radius: 8px;
    color: var(--q-secondary);
    background-color: var(--secondary-bground);

    span {
        color: var(--q-secondary);
    }
}

:deep(.q-stepper__tab--done .q-stepper__dot),
:deep(.q-stepper__tab--active .q-stepper__dot) {
    fill: var(--q-primary);
    color: var(--q-primary);
    background-color: var(--primary-light);

    :before {
        color: var(--q-primary);
    }
}
</style>
