import utils from '@/shared/plugins/utils'
import store from '@/store'

export default (request) => {
    try {
        const config = store.getters.getConfig

        const isDispatch = config.DISPATCHED_DEPLOYMENTS?.length > 0
        request.deployment = isDispatch ? request.valuation_request_ref.split('_')[0] : false
        request.displayRequestRef = isDispatch
            ? request.valuation_request_ref.split('_')[1]
            : request.valuation_request_ref

        request.badges = []
        if (request.status === 'submitted') {
            if (
                request.last_dvm_action_at &&
                new Date(request.last_submission_at).getTime() > request.last_dvm_action_at
            )
                request.badges.push('resubmitted')

            const apt_status = request.latest_appointment?.status
            const payment_status = request.payment_summary?.status
            if (request.borrower_status === 'registration_pending') {
                request.badges.push('needs_borrower_email')
            } else if (
                payment_status === 'payment_needed' ||
                payment_status === 'payment-pending' ||
                request.borrower_status === 'borrower-details'
            ) {
                request.badges.push('needs_borrower_action')
            } else {
                switch (apt_status) {
                    case 'pending_confirmation':
                        request.badges.push('appointment_pending_confirmation')
                        break
                    case 'cancelled':
                        request.badges.push('appointment_cancelled')
                        break
                    case 'cancelled_by_valuer':
                        request.badges.push('appointment_cancelled_by_valuer')
                        break
                    case 'confirmed':
                        request.badges.push('appointment_confirmed')
                        break
                }
            }
        }

        if (request.valuation) {
            var valuation = { ...request.valuation }
            if (valuation.date) {
                valuation.date = new Date(Array.isArray(valuation.date) ? valuation.date[0] : valuation.date)
                if (typeof valuation.date === 'string') {
                    valuation.date = utils.parseFUDate(valuation.date)
                }
            }
            // TODO: currently ignoring the override_price logic (dvm only). decide whether it's relevant long term and include it here accordingly
            // TODO: check if it is needed to default valuation.internal_remarks to ''
            // isLoading.value = false

            // TODO: do we really need this insanity in the frontend?

            // FIXME: this seems to be defined already at the root level:
            valuation.package_type =
                request.features.renovation_cost >= 10000 ? 'renovation_light' : 'classic'

            if ((valuation.market_value ?? null) === null && request.avm_transaction?.status == 200) {
                valuation.type = 'avm'
                valuation.market_value = request.avm_transaction.body.estimation.asking_price_q50

                valuation.valuer = '-'
                valuation.date = new Date(Date.now())
                if (request.avm_rental.status == 200 && (valuation.rental_value ?? null) === null) {
                    valuation.rental_value = request.avm_rental.body.estimation.asking_price_q50
                }
                if (valuation.market_value && (valuation.forced_sale_value ?? null) === null) {
                    valuation.forced_sale_value = utils.forced_sale_value(valuation.market_value)
                }
            }
            if (
                !valuation.market_value_post_renovation &&
                valuation.date &&
                new Date(valuation.date) < new Date(2023, 8, 17, 0, 0, 0) &&
                valuation.package_type === 'renovation_light'
            ) {
                if (valuation.date < new Date(2022, 5, 10, 0, 0, 0)) {
                    valuation.market_value_post_renovation =
                        valuation.market_value + request.features.renovation_cost / 4
                    valuation.market_value = valuation.market_value - request.features.renovation_cost / 2
                } else {
                    valuation.market_value_post_renovation =
                        valuation.market_value + (request.features.renovation_cost * 3) / 4
                }
                valuation.forced_sale_value_post_renovation = utils.forced_sale_value(
                    valuation.market_value_post_renovation
                )
            }

            if (
                !valuation.market_value_pre_construction &&
                valuation.date &&
                new Date(valuation.date) < new Date(2023, 8, 17, 0, 0, 0) &&
                request.features.f_parcel_cost
            ) {
                valuation.market_value_pre_construction = request.features.f_parcel_cost
                valuation.forced_sale_value_pre_construction = utils.forced_sale_value(
                    request.features.f_parcel_cost
                )
            }

            if (
                !valuation.construction_cost &&
                valuation.date &&
                new Date(valuation.date) < new Date(2023, 8, 17, 0, 0, 0) &&
                request.features.construction_cost
            ) {
                valuation.construction_cost = request.features.construction_cost
            }

            request.valuation = valuation
            // End of Valuation post-treatment
        }

        const recompute_risk = (dist, buffer) => {
            return isNaN(dist) ? null : dist <= buffer ? 'High' : 'None'
        }

        // TODO: move this to the backend FFS
        if (request.features) {
            const f_number_of_facades =
                request.features.f_touching_sides === 0
                    ? 'detached'
                    : request.features.f_touching_sides === 1
                      ? 'semi'
                      : 'attached'

            request.features = {
                ...request.features,
                f_number_of_facades,
                f_flood_risk_insurance_exclusion_1m: recompute_risk(
                    request.features.f_dist_building_flood_insurance_exclusion,
                    1
                ),
                f_parcel_flood_risk_insurance_exclusion_1m: recompute_risk(
                    request.features.f_dist_parcel_flood_insurance_exclusion,
                    1
                ),
            }
        }

        // TODO: figure out a way to have formatted reactive synthetic properties (like fullAddress)
        // without having to use a closure (eg that can leave outside of the request scope)
        // -> alternatively, we only use getXXX() methods
        // TODO: move these functions out of utils and back here
        let features = request.ers_data || request.features
        if (request.address) {
            request.fullAddress = utils.full_address(request.address, features)
            request.viaAddress = utils.via_address(request.address, features)
            request.shortAddress = utils.short_address(request.address, features)
            request.displayAddress =
                request.viaAddress !== ''
                    ? `${request.fullAddress} (via ${request.viaAddress})`
                    : request.fullAddress
            // request.formattedAddress = utils.formatAddress(request.address)
            request.formattedAddress = {
                firstLine:
                    request.address.streetname && request.address.streetnumber
                        ? `${request.address.streetname} ${request.address.streetnumber}${
                              request.address.boxnumber ? ` b ${request.address.boxnumber}` : ''
                          }`
                        : 'n/a',
                secondLine:
                    request.address.postalcode && request.address.municipality
                        ? `${request.address.postalcode} ${request.address.municipality}`
                        : 'n/a',
            }
        }

        request.regionName = features?.region && utils.iso_regions[features?.region]

        return {
            ...request,
            ers_data: features,
            // address: displayAddress(request),
            document_info: request.document_info ?? {},
            building_type: features?.f_building_type ?? null,
            modified_at: new Date(request.modified_at),
            created_at: new Date(request.created_at),
            last_dvm_action_at: request.last_dvm_action_at ? new Date(request.last_dvm_action_at) : null,
            referral_at: request.referral_at ? new Date(request.referral_at) : null,
            valuation,
            request_language: request.last_submission_lang,
        }
    } catch (error) {
        // TODO: handle this in a cleaner way (proper error logging to the store)
        console.error({ error, request })
        return { error: true }
    }
}
