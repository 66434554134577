const ersRoutes = [
    {
        path: '/ers',
        name: 'ers',
        redirect: { name: 'ers.request' },
    },
    {
        path: '/ers/request/:action?',
        name: 'ers.request',
        component: () => import('./ErsLayout.vue'),
        children: [
            {
                path: '',
                params: {
                    action: 'result',
                },
                name: 'ers.result',
                component: () => import('./ErsLayout.vue'),
                meta: {
                    public: true,
                },
            },
        ],
    },
    // {
    //     path: '/ers/result',
    //     name: 'ers.result',
    //     meta: {
    //         public: true,
    //     },
    //     component: () => import('./components/result/Result.vue'),
    // },
    {
        path: '/ers/report',
        name: 'ers.report',
        component: () => import('./components/result/Report.vue'),
    },
]

export const ersSteps = {
    WELCOME: 'welcome',
    CUSTOMER_REF: 'customer-ref',
    ESTIMATION_TYPE: 'estimation-type',
    ADDRESS: 'address',
    BUILDING_INFO: 'building-info',
    ATTIC: 'attic',
    BASEMENT: 'basement',
    BUILDING_DIMENSIONS: 'building-dimensions',
    BUILDING_DIMENSIONS_ALT: 'building-dimensions-alt',
    LIVING_AREA: 'living-area',
    EPC_INFORMATION: 'epc-information',
    EPC: 'epc',
    EPC_SCORE: 'epc-score',
    CONSTRUCTION_YEAR: 'construction-year',
    ROOF: 'roof',
    WALL: 'wall',
    FLOOR: 'floor',
    WINDOW_GLAZING: 'window-glazing',
    HEATING: 'heating',
    HEATING_ALT: 'heating-alt',
    SOLAR_PANELS: 'solar-panels',
    END_SECTION: 'end-section',
    CONSUMPTION: 'consumption',
    ENERGY_CONSUMPTION: 'energy-consumption',
    CONSUMERS: 'consumers',
    BIG_CONSUMERS: 'big-consumers',
    WAITING_FOR_OCR: 'waiting-for-ocr',
    FAILED_OCR: 'failed-ocr',
    SUBMIT_SUMMARY: 'submit-summary',
    PROCESSING: 'processing',
    RESULT: 'result',
}

export default ersRoutes
