<template>
    <!-- NOTE: q-page sets min-height, but not height, which results in descendents
      not having a set height or min-height, unless explicitly set.
      using % on descendents height will fail unless we wrap everything in a grid layout: -->
    <q-page
        class="page"
        :class="[
            isStacked ? 're-stacked' : 're-side-by-side',
            $slots.sidebar || flow ? 'with-sidebar' : null,
            props.isCentered && !$q.screen.xs ? 're-centered' : null,
        ]"
    >
        <!-- optional sidebar it if exists -->
        <div
            v-if="$slots.sidebar || (flow && !flow.currentStepMeta.isStepperHidden)"
            class="sidebar"
            :class="{ 'sticky-top': props.stickyTop, 'limit-width-above-sm': !isStacked }"
        >
            <slot v-if="$slots.sidebar" name="sidebar"></slot>
            <stepper
                v-else-if="flow && flow.isEnabled"
                :flow="props.flow"
                v-bind="flowProps"
                :is-horizontal="isStacked"
                :mark-steps-done="markStepsDone"
            >
                <template v-for="slot in Object.keys($slots).filter((s) => s.startsWith('stepper-'))" #[slot]>
                    <slot :name="slot"></slot>
                </template>
            </stepper>
        </div>
        <div
            class="page__main"
            :class="{
                [`width-${props.maxWidth}`]: props.maxWidth !== 'full',
            }"
            style="width: 100%"
        >
            <template v-if="$slots.banner">
                <slot name="banner"></slot>
            </template>
            <div
                :class="{
                    [props.mainContentClass]: !!props.mainContentClass,
                    fit: !$slots.banner,
                }"
            >
                <re-title
                    v-if="props.pageTitle || props.tooltip"
                    :tooltip="props.tooltip"
                    :class="props.centerTitle ? 'row justify-center' : ''"
                >
                    {{ props.pageTitle }}
                </re-title>
                <slot></slot>
            </div>
        </div>
        <q-page-sticky
            v-if="props.showBackground"
            :class="{ 'themed-asset': props.themedBackground }"
            position="bottom-left"
            :style="{ ...defaultBackgroundStyle, ...backgroundStyle }"
        />
    </q-page>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useQuasar } from 'quasar'
import Stepper from '@/components/elements/Stepper.vue'

const props = defineProps({
    // Move to template props:
    maxWidth: {
        type: String,
        default: 'md',
        validator: (v) => ['sm', 'md', 'lg', 'xl', 'full'].includes(v),
    },
    tooltip: {
        type: String,
        default: undefined,
    },
    backgroundStyle: {
        type: Object,
        default: undefined,
    },
    themedBackground: {
        type: Boolean,
        default: false,
    },
    showBackground: {
        type: Boolean,
        default: false,
    },
    pageTitle: {
        type: String,
        default: '',
    },
    centerTitle: {
        type: Boolean,
        default: false,
    },
    noPadding: {
        type: Boolean,
        default: false,
    },
    isCentered: {
        type: Boolean,
        default: false,
    },
    mainContentClass: {
        type: String,
        default: 're-main-content',
    },
    flow: {
        type: Object,
        default: undefined,
    },
    flowProps: {
        type: Object,
        default: undefined,
    },
    stacked: {
        type: Boolean,
        default: undefined,
    },
    stickyTop: {
        type: Boolean,
        default: true,
    },
    markStepsDone: {
        type: Boolean,
        default: true,
    },
})

const q = useQuasar()

const isStacked = computed(() => props.stacked ?? q.screen.lt.md)

const backgroundUrl = new URL('@/assets/icons/img-skyline.svg', import.meta.url).pathname

const defaultBackgroundStyle = ref({
    background: `url('${backgroundUrl}') no-repeat`,
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'center',
    height: '100%',
    width: '100%',
    zIndex: -100,
})
</script>
<style scoped lang="scss">
.page {
    .sidebar {
        background-color: $base;
        z-index: 1001;
    }

    .limit-width-above-sm {
        @media (min-width: $breakpoint-sm-max) {
            max-width: 300px;
        }
    }

    &.re-stacked {
        display: grid;
        grid-template-columns: 1fr;

        &.with-sidebar {
            grid-template-rows: min-content auto;
        }

        .sticky-top {
            top: 50px;
        }

        &.re-centered {
            justify-content: center;
            justify-items: center;
        }
    }

    &.re-side-by-side {
        display: flex;
        flex-flow: row nowrap;

        &.with-sidebar {
            .page__main {
                margin: 0;
            }
        }

        .sidebar {
            flex-shrink: 0;
            min-width: 250px; // FIXME: define reactive min stepper width
            height: calc(100vh - 50px); // Needed for sticky to work
        }

        &.re-centered {
            justify-content: center;
            justify-items: center;
        }
    }

    .sticky-top {
        position: sticky;
        top: 50px;
        overflow-y: auto;
    }
}
</style>
