const enums = {}

enums.roles = [
    '-:admin',
    '-:developer',
    'cat:submitter',
    'ers:submitter',
    'ers:substitute',
    'ers:reporter',
    'dvm:submitter',
    'dvm:substitute',
    'dvm:valuer',
    'dvm:dispatcher',
    'dvm:reporter',
    'ovm:submitter',
    'ovm:valuer',
    'ovm:borrower',
    'ovm:dispatcher',
    'ovm:reporter',
    'avm:submitter',
    'ers:referrer',
    'ers:borrower',
    'ers:reporter',
    'oepc:submitter',
    'oepc:borrower',
    'oepc:valuer',
    'oepc:dispatcher',
    'oepc:reporter',
]

enums.property_destination = ['primary', 'secondary', 'buy_to_let']

enums.defaultConfig = {
    fileMaxSize: Math.pow(1024, 2) * 2,
}

enums.const = {
    attic_height: 2,
    floor_height: 2.8,
    attic_floor_factor: 0.8,
}

enums.iso_regions = {
    brussels: 'BE-BRU',
    flanders: 'BE-VLG',
    wallonia: 'BE-WAL',
}

enums.allRenovations = [
    {
        name: 'roofIn',
        type: 'roof',
    },
    {
        name: 'atticFloor',
        type: 'roof',
    },
    {
        name: 'wallExt',
        type: 'wall',
    },
    {
        name: 'windowsDouble',
        type: 'window',
    },
    {
        name: 'condGas',
        type: 'heating',
    },
    {
        name: 'heatPump',
        type: 'heating',
    },
    {
        name: 'wallCav',
        type: 'wall',
    },
    {
        name: 'unitPv',
        type: 'solar_panel',
    },
    {
        name: 'roofExt',
        type: 'roof',
    },
]

enums.ErsInputsValidation = {
    installation_year: {
        min: 1989,
    },
    f_construction_year: {
        min: 1700,
    },
    renovation_year: {
        min: 1700,
    },
    f_building_area: {
        min: 0,
        max: 1500,
    },
    f_roof_area: {
        min: 0,
        max: 1500,
    },
    exterior_wall_area: {
        min: 0,
        max: 3000,
    },
    f_living_area: {
        min: 18,
        max: 1500,
    },
    photovoltaic_surface: {
        min: 1.65,
        max: 60,
    },
    f_best_pane_area: {
        min: 2.0625,
        max: 75,
    },
}

enums.directions = {
    LEFT: 'left',
    RIGHT: 'right',
}

enums.bankPrePaymentAmounts = [0, 10, 20, 50, 100, 200]

enums.heatingTypes = [
    {
        fuel: 'gas',
        icon: 'heating-fuel-gas',
        types: [
            {
                heating_type: 'boiler',
                subtype: 'standard',
                id: 'gas_boiler',
            },
            {
                heating_type: 'boiler',
                subtype: 'condensing',
                id: 'gas_condensing',
            },
            {
                heating_type: 'stove',
                subtype: null,
                id: 'gas_stove',
            },
        ],
    },
    {
        fuel: 'oil',
        icon: 'heating-fuel-oil',
        types: [
            {
                heating_type: 'boiler',
                subtype: 'standard',
                id: 'oil_standard',
            },
            {
                heating_type: 'boiler',
                subtype: 'condensing',
                id: 'oil_condensing',
            },
            {
                heating_type: 'stove',
                subtype: null,
                id: 'oil_stove',
            },
        ],
    },
    {
        fuel: 'electricity',
        icon: 'heating-fuel-electric',
        types: [
            {
                heating_type: 'heat_pump',
                subtype: null,
                id: 'electricity_heat_pump',
            },
            {
                heating_type: 'electric_heater',
                subtype: null,
                id: 'electricity_electric_heater',
            },
        ],
    },
    {
        fuel: 'wood',
        icon: 'heating-fuel-wood',
        heating_type: 'stove',
        id: 'wood_stove',
    },
    {
        fuel: 'pellets',
        icon: 'heating-fuel-pellets',
        heating_type: 'stove',
        id: 'pellets_stove',
    },
    {
        fuel: 'coal',
        icon: 'heating-fuel-coal',
        heating_type: 'stove',
        id: 'coal_stove',
    },
    {
        fuel: null,
        icon: 'heating-fuel-district-heating',
        heating_type: 'district_heating',
        id: 'district_heating',
    },
]

enums.renovationWarningRules = [
    // x: a selected renovation type
    // y: an array of renovation types that are available but not selected
    {
        x: 'window',
        y: ['roof', 'wall'],
    },
    {
        x: 'solar_panel',
        y: ['roof'],
    },
    {
        x: 'heating',
        y: ['roof', 'window', 'wall'],
    },
]

enums.epcMapping = {
    [enums.iso_regions.wallonia]: {
        'A++': [-Infinity, 0],
        'A+': [0, 45],
        A: [45, 85],
        B: [85, 170],
        C: [170, 255],
        D: [255, 340],
        E: [340, 425],
        F: [425, 510],
        G: [510, Infinity],
    },
    [enums.iso_regions.flanders]: {
        'A+': [-Infinity, 0],
        A: [0, 100],
        B: [100, 200],
        C: [200, 300],
        D: [300, 400],
        E: [400, 500],
        F: [500, Infinity],
    },
    [enums.iso_regions.brussels]: {
        A: [-Infinity, 45],
        B: [45, 95],
        C: [95, 150],
        D: [150, 210],
        E: [210, 275],
        F: [275, 345],
        G: [345, Infinity],
    },
}

enums.epcColors = {
    [enums.iso_regions.wallonia]: {
        'A++': '#00bd00',
        'A+': '#00bd00',
        A: '#00bd00',
        B: '#9ade27',
        C: '#d8db1a',
        D: '#fdf300',
        E: '#fdd400',
        F: '#fa9101',
        G: '#d62015',
    },
    [enums.iso_regions.flanders]: {
        'A+': '#00bd00',
        A: '#9ade27',
        B: '#d8db1a',
        C: '#fdf300',
        D: '#fdd400',
        E: '#fa9101',
        F: '#d62015',
    },
    [enums.iso_regions.brussels]: {
        A: '#00bd00',
        B: '#9ade27',
        C: '#d8db1a',
        D: '#fdf300',
        E: '#fdd400',
        F: '#fa9101',
        G: '#d62015',
    },
}

enums.consumptions = {
    canonical_unit: {
        [enums.iso_regions.brussels]: {
            electricity: {
                min: 1000,
                med: 2600,
                max: 6600,
            },
            electricity_heat: {
                min: 3153,
                med: 7884,
                max: 17560,
            },
            gas: {
                min: 595,
                med: 1459,
                max: 3027,
            },
            oil: {
                min: 457,
                med: 1121,
                max: 2325,
            },
            wood: {
                min: 1420,
                med: 2557,
                max: 4048,
            },
            pellets: {
                min: 1380,
                med: 2483,
                max: 3932,
            },
            coal: {
                min: 768,
                med: 1382,
                max: 2188,
            },
            district_heating: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
        },
        [enums.iso_regions.flanders]: {
            electricity: {
                min: 1600,
                med: 3600,
                max: 7600,
            },
            electricity_heat: {
                min: 10295,
                med: 19251,
                max: 32281,
            },
            gas: {
                min: 1081,
                med: 1946,
                max: 3081,
            },
            oil: {
                min: 1205,
                med: 2169,
                max: 3434,
            },
            wood: {
                min: 1420,
                med: 2557,
                max: 4048,
            },
            pellets: {
                min: 1380,
                med: 2483,
                max: 3932,
            },
            coal: {
                min: 768,
                med: 1382,
                max: 2188,
            },
            district_heating: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
        },
        [enums.iso_regions.wallonia]: {
            electricity: {
                min: 1600,
                med: 3800,
                max: 9200,
            },
            electricity_heat: {
                min: 7629,
                med: 15223,
                max: 27604,
            },
            gas: {
                min: 1027,
                med: 1946,
                max: 2900,
            },
            oil: {
                min: 1067,
                med: 2022,
                max: 3258,
            },
            wood: {
                min: 1304,
                med: 2471,
                max: 3981,
            },
            pellets: {
                min: 1267,
                med: 2400,
                max: 3866,
            },
            coal: {
                min: 705,
                med: 1336,
                max: 2152,
            },
            district_heating: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
        },
    },
    kwh: {
        [enums.iso_regions.brussels]: {
            electricity: {
                min: 1000,
                med: 2600,
                max: 6600,
            },
            electricity_heat: {
                min: 3153,
                med: 7884,
                max: 17560,
            },
            gas: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
            oil: {
                min: 4603,
                med: 11297,
                max: 23432,
            },
            wood: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            pellets: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            coal: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            district_heating: {
                min: 5500,
                med: 13500,
                max: 28000,
            },
        },
        [enums.iso_regions.flanders]: {
            electricity: {
                min: 1600,
                med: 3600,
                max: 7600,
            },
            electricity_heat: {
                min: 10295,
                med: 19251,
                max: 32281,
            },
            gas: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
            oil: {
                min: 12146,
                med: 21862,
                max: 14615,
            },
            wood: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            pellets: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            coal: {
                min: 6250,
                med: 11250,
                max: 17812,
            },
            district_heating: {
                min: 10000,
                med: 18000,
                max: 28500,
            },
        },
        [enums.iso_regions.wallonia]: {
            electricity: {
                min: 1600,
                med: 3800,
                max: 9200,
            },
            electricity_heat: {
                min: 7629,
                med: 15223,
                max: 27604,
            },
            gas: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
            oil: {
                min: 10757,
                med: 20383,
                max: 32839,
            },
            wood: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            pellets: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            coal: {
                min: 5738,
                med: 10871,
                max: 17515,
            },
            district_heating: {
                min: 9500,
                med: 18000,
                max: 29000,
            },
        },
    },
}

enums.documentSource = {
    VALUER: 'valuer',
    DISPATCHER: 'dispatcher',
    BORROWER: 'borrower',
    OWNER: 'owner',
    GOOGLE_STREETVIEW: 'google_streetview',
    ROCKESTATE: 'rockestate',
}

enums.catalog = {
    ALLOW_3D_LEVEL: 3,
    SECTION_NAMES: [
        'warnings',
        'address_location',
        'c_building',
        'energy',
        'transaction',
        'secondary_buildings',
        'c_parcels',
        'reconstruction_value',
        'economic_activity',
        'roof',
        'flood',
    ],
}

enums.ocrStatus = {
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILED: 'failed',
}

enums.ersEstimationType = {
    HCE: 'hce',
    EPC_BASED: 'epc_based',
}

enums.ocrStatus = {
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILED: 'failed',
}

export default enums
