<template>
    <q-banner
        rounded
        class="banner"
        :class="[`banner_${props.color}`, 'q-pa-md', props.orientation]"
        v-bind="$attrs"
    >
        <template v-if="!props.noIcon" #avatar>
            <q-avatar :icon="iconName" size="xl" />
        </template>
        <h6 v-if="props.title" class="text-bold q-pb-sm">{{ props.title }}</h6>
        <slot></slot>
        <template v-if="!!$slots['action']" #action>
            <div class="q-mt-md">
                <slot name="action"></slot>
            </div>
        </template>
    </q-banner>
</template>
<script setup>
import { computed } from 'vue'

const props = defineProps({
    color: {
        type: String,
        validator(value) {
            // The value must match one of these strings
            return ['positive', 'warning', 'negative', 'info', 'primary'].includes(value)
        },
        default: 'info',
    },
    orientation: {
        type: String,
        validator(value) {
            return ['row', 'column'].includes(value)
        },
        default: 'row',
    },
    noIcon: { type: Boolean, default: false },
    title: { type: String, default: null },
})

const iconName = computed(() => {
    return {
        positive: 'mdi-check',
        warning: 'mdi-alert-outline',
        negative: 'mdi-alert-octagon',
        info: 'mdi-lightbulb-outline',
        primary: 'mdi-lightbulb-outline',
    }[props.color]
})
</script>

<style scoped lang="scss">
/* Q-banner is adding text-body2 class on default content, so we disable some properties */
.banner {
    min-height: fit-content;

    :deep(.text-body2) {
        font-weight: 500;
    }

    :deep(.q-banner__content p:last-child) {
        margin: 0;
    }

    :deep(.q-banner__avatar) {
        align-self: center !important;
    }

    &_positive {
        background-color: $positive-bground;

        :deep(.q-banner__content) {
            color: $positive-dark;

            h6 {
                color: $positive-dark;
            }

            a {
                color: $positive-dark !important;
                text-decoration: underline !important;
            }
        }

        :deep(.q-icon) {
            color: $positive;
        }

        :deep(.q-avatar) {
            background-color: $positive-border;
        }
    }

    // @TODO: #colors light is not used, made a more helpful variant based on the primary color
    &_primary {
        background-color: var(--primary-bground);

        :deep(.q-banner__content) {
            color: var(--primary-dark);

            h6 {
                color: var(--primary-dark);
            }

            a {
                color: var(--primary-dark) !important;
                text-decoration: underline !important;
            }
        }

        :deep(.q-icon) {
            color: var(--q-primary);
        }

        :deep(.q-avatar) {
            background-color: var(--primary-light) !important;
        }
    }

    &_info {
        background-color: var(--info-bground);

        :deep(.q-banner__content) {
            color: var(--info-dark);

            h6 {
                color: var(--info-dark);
            }

            a {
                color: var(--info-dark) !important;
                text-decoration: underline !important;
            }
        }

        :deep(.q-icon) {
            color: var(--q-info);
        }

        :deep(.q-avatar) {
            background-color: var(--info-border) !important;
        }
    }

    &_warning {
        background-color: $warning-bground;

        :deep(.q-banner__content) {
            color: $warning-dark;

            h6 {
                color: $warning-dark;
            }

            a {
                color: $warning-dark !important;
                text-decoration: underline !important;
            }
        }

        :deep(.q-icon) {
            color: $warning;
        }

        :deep(.q-avatar) {
            background-color: $warning-border;
        }
    }

    &_negative {
        background-color: $negative-bground;

        :deep(.q-banner__content) {
            color: $negative-dark;

            h6 {
                color: $negative-dark;
            }

            a {
                color: $negative-dark !important;
                text-decoration: underline !important;
            }
        }

        :deep(.q-icon) {
            color: $negative;
        }

        :deep(.q-avatar) {
            background-color: $negative-light;
        }
    }
}
</style>
