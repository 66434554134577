<!-- eslint-disable vue/no-v-html -->
<template>
    <re-page :page-title="$t('section.helpcenter')" :background="true">
        <!-- <template #sidebar>
            <ul class="toc">
                <li
                    v-for="subItems in items.filter((i) => Object.keys(i.entries).length)"
                    :key="subItems.title"
                >
                    <a :href="`#${subItems.title}`">{{ subItems.title }}</a>
                    <ul>
                        <li v-for="category in Object.keys(subItems.entries)" :key="category">
                            <a :href="`#section-${category}`">{{ $t(`section.${category}`) }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </template> -->
        <re-input
            v-model="searchField"
            :placeholder="$t('monolith.shared.search')"
            clearable
            class="search-input"
        />
        <template v-for="subItems in items" :key="subItems.title">
            <template v-if="Object.entries(subItems.entries).length">
                <a :name="subItems.title"></a>
                <h2>{{ subItems.title }}</h2>
                <template v-for="[category, subSubItems] in Object.entries(subItems.entries)" :key="category">
                    <a :name="`section-${category}`"></a>
                    <h3 class="q-pb-sm">{{ $t(`section.${category}`) }}</h3>
                    <q-list>
                        <!-- TODO: default-opened might not be getting updated when filter changes -->
                        <q-expansion-item
                            v-for="item in subSubItems"
                            :key="item.name"
                            v-model="item.isOpen.value"
                            class="faq-item re-mb rounded-borders overflow-hidden"
                        >
                            <template #header>
                                <q-item-section>
                                    <h4>
                                        <a :id="item.name" :name="item.name" style="color: inherit">
                                            {{ $t(`faq.${item.name}_question`) }}
                                        </a>
                                    </h4>
                                </q-item-section>
                                <q-item-section side no-wrap class="q-gutter-xs">
                                    <div class="row">
                                        <q-avatar
                                            v-for="icon in iconsShown(item)"
                                            :key="icon"
                                            size="sm"
                                            round
                                        >
                                            <re-svg :name="icon" subdir="request-types" />
                                        </q-avatar>
                                    </div>
                                </q-item-section>
                            </template>
                            <q-separator />
                            <q-card>
                                <q-card-section>
                                    <!-- TODO: can we have some sort of loading indicator for videos? -->
                                    <!-- TODO: add max-height on video based on window height -->
                                    <!-- TODO: figure out why v-html no longer works (inside q-page?) -->
                                    <div v-if="subItems.isVideo" v-html="$t(`faq.${item.name}_answer`)"></div>
                                    <vue-markdown
                                        v-else
                                        class="markdown-text"
                                        :source="$t(`faq.${item.name}_answer`)"
                                    />
                                </q-card-section>
                            </q-card>
                        </q-expansion-item>
                    </q-list>
                </template>
            </template>
        </template>
        <q-card v-if="$config.SUPPORT_CONTACT" class="justify-between items-center q-pa-md">
            <q-card-section horizontal class="justify-between">
                <h4 class="self-center">{{ $t('global.helpcenter.question') }}</h4>
                <q-card-actions vertical>
                    <re-button
                        color="main"
                        :label="$t('global.helpcenter.contact_cta')"
                        @click="isContactModalActive = true"
                    />
                </q-card-actions>
            </q-card-section>
        </q-card>
        <contact-form-faq
            v-model="isContactModalActive"
            wide-dialog
            has-close-button
            @hide-contact-modal="isContactModalActive = false"
        />
    </re-page>
</template>
<script setup>
import { inject, computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import utils from '@/shared/plugins/utils'
import { config } from '@/shared/assets/config/config'
import ContactFormFaq from './ContactForm.vue'
import VueMarkdown from 'vue-markdown-render'
import { useHead } from '@unhead/vue'

// const questionRefs = ref({})
const curUser = inject('curUser')
const conf = inject('config')

const searchField = ref('')
const isContactModalActive = ref(false)

const { t } = useI18n()
const route = useRoute()

// All those modules are grouped under the same icon
const iconGroups = {
    dvm: ['dvm', 'ovm', 'borrower'],
    ers: ['ers'],
}

const modulesShown = computed(() => ({
    dvm: curUser.accessibleModules.includes('dvm'),
    ovm:
        (curUser.accessibleModules.includes('dvm') && conf.ENABLE_OVM) ||
        curUser.accessibleModules.includes('ovm'),
    ers: curUser.accessibleModules.includes('ers'),
    borrower: curUser.is.onlyBorrower,
}))

useHead({
    title: 'Support',
    titleTemplate: '%s | Rock.estate Valuation App',
})

const iconsShown = (item) => {
    return Object.entries(iconGroups)
        .map(([icon, modules]) => {
            if (item.modules.some((mod) => modules.includes(mod))) {
                return icon
            }
        })
        .filter((icon) => icon)
}

const items = computed(() => {
    let availableItems = config.filter(
        (item) => item.functionality === 'faq' && item.modules.some((mod) => modulesShown.value[mod])
    )

    let availableVideos = {},
        availableFaqs = {}

    // // TODO: the flow on this seems needlessly convoluted: make product specs more consistent
    availableItems.forEach((item) => {
        const isSearchFilter = !utils.isEmptyStr(searchField.value)

        const isSearchQueryInQuestion =
            !isSearchFilter ||
            t(`faq.${item.name}_question`).toUpperCase().includes(searchField.value.toUpperCase())
        const isSearchQueryInAnswer =
            !isSearchFilter ||
            t(`faq.${item.name}_answer`).toUpperCase().includes(searchField.value.toUpperCase())

        item.isSelected = route.hash.substring(1) === item.name
        item.isOpen = ref(item.isSelected)

        // TODO: refactor to store isVideo separately and store video metadata outside of i18n strings
        if (t(`faq.${item.name}_answer`).includes('https://share.synthesia.io/embeds/videos/')) {
            // Video FAQ
            if (!isSearchQueryInQuestion) {
                return
            }
            if (!(item.sections[0] in availableVideos)) {
                availableVideos[item.sections[0]] = []
            }
            availableVideos[item.sections[0]].push(item)
        } else {
            if (isSearchFilter) {
                if (isSearchQueryInAnswer) {
                    item.isOpen.value = true
                } else if (!isSearchQueryInQuestion) {
                    return
                }
            }

            if (!(item.sections[0] in availableFaqs)) {
                availableFaqs[item.sections[0]] = []
            }
            availableFaqs[item.sections[0]].push(item)
        }
    })
    return [
        {
            entries: availableVideos,
            isVideo: true,
            title: t('global.helpcenter.video_tutorials'),
        },
        {
            entries: availableFaqs,
            isVideo: false,
            title: t('global.helpcenter.faq'),
        },
    ]
})

// TODO: use curRequest inside contact form instead of this:
// const showContactModal = () => {
//     if (route.query.valuation_request_ref) {
//         contactForm.value.valuation_request_ref = route.query.valuation_request_ref
//     }
//     isContactModalActive.value = true
// }
</script>
<style lang="scss" scoped>
:deep(.markdown-text) {
    h2 {
        font-size: 16px;
    }
}

.faq-item {
    border: 1px solid $border;
}

.search-input {
    // position: sticky;
    // top: 1em;
    background-color: $base;
    // z-index: 1;
}

a,
a:visited {
    color: var(--q-primary);
    text-decoration: none;
}

ul {
    list-style-type: none;
    padding: 0.5em 1em;
}

ul.toc {
    padding: 0;
    ul {
        padding: 0;
    }
    li {
        padding: 0.15em 1em;
        margin-top: 0.15em;
    }

    a {
        color: var(--q-secondary);
    }
    a:hover {
        color: var(--q-primary);
    }

    li.scrolled {
        background-color: var(--secondary-light);
    }
    li.active {
        background-color: var(--q-primary);
        a {
            color: $base;
        }
    }
}

// .contact-link {
//     padding: 30px;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
// }
</style>
